import qs from "qs";
import axios from "axios";

const apiClient = axios.create({
  baseURL: `` + process.env.VUE_APP_AXIOS_BASE_URL + ``,
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getCall: async function ($auth:any, apiroute:any) {
    // const token = await $auth.getTokenSilently();

    // console.log(apiClient.baseURL + "baseURL");

    // apiClien(t.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //console.log(apiroute);
    return apiClient.get(apiroute);
  },

  putCall: async function ($auth:any, apiroute:any, data:any) {
    // const token = await $auth.getTokenSilently();

    // apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    return apiClient.put(apiroute, data);
  },

  // postCall: async function () {
  //   axios({
  //     method: "POST",
  //     //url: "https://dev-nr8r7cro.eu.auth0.com/oauth/token",
  //     data: qs.stringify({
  //       //   grant_type: "client_credentials",
  //       //   client_id:
  //       //     "" + process.env.VUE_APP_AUTH0_ClientIdApiExplorerApplication + "",
  //       //   client_secret:
  //       //     "" +
  //       //     process.env.VUE_APP_AUTH0_ClientSecretApiExplorerApplication +
  //       //     "",
  //       //   audience:
  //       //     "" + process.env.VUE_APP_AUTH0_AudienceApiExplorerApplication + "",
  //     }),
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //   })
  //     .then((response) => {
  //       console.log(response.data.access_token);
  //       console.log($auth.user.sub);
  //       axios({
  //         method: "POST",
  //         url: "https://dev-nr8r7cro.eu.auth0.com/api/v2/jobs/verification-email",
  //         data: qs.stringify({
  //           user_id: $auth.user.sub,
  //           client_id:
  //             "" +
  //             process.env.VUE_APP_AUTH0_ClientIdApiExplorerApplication +
  //             "",
  //         }),
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           Authorization: "Bearer " + response.data.access_token,
  //         },
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // },
};
