<template>
  <div id="app">
    <NavBar />
    <!-- <keep-alive include="deviceList"> -->
    <router-view />
    <!-- </keep-alive> -->
    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

@Component({
  components: {
    NavBar,
    Footer,
  },
})
export default class App extends Vue {}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  
}
.test {
  text-align: center;
  margin-left: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.5em;
}
</style>
