<template>
  <div>
    <p class="test">Staking Info</p>

    <b-button-toolbar key-nav aria-label="Toolbar with button groups">
      <b-button-group class="mx-1">
        <b-button variant="light" @click="apiCall(selectedKey)">
          <b-icon icon="arrow-counterclockwise" aria-hidden="true"></b-icon>
          Refresh
        </b-button>
        <b-dropdown
          id="dropdown-1"
          :text="apiKeyPublic[selectedKey].key"
          class="m-md-2"
          split
          split-variant="light"
          variant="primary"
        >
          <b-dropdown-item
            @click="dropdownClick(index)"
            v-for="(s, index) in apiKeyPublic"
            :key="index"
          >
            {{ s.key }}</b-dropdown-item
          >
        </b-dropdown>
      </b-button-group>
    </b-button-toolbar>

    <b-list-group>
      <b-list-group-item v-for="(s, index) in stakingInfo" :key="index">
        Date: {{ s.d }}
        <b-icon
          v-if="s.status == 0"
          icon="exclamation-circle-fill"
          variant="danger"
        ></b-icon>
        <b-icon
          v-if="s.status == 1"
          icon="exclamation-circle-fill"
          variant="warning"
        ></b-icon>
        <b-icon
          v-if="s.status == 2"
          icon="check-circle-fill"
          variant="success"
        ></b-icon>
        <br />
        Time Gap: {{ s.timeGap }} <br />
        Blocks Wallet: {{ s.blocks }} <br />
        Blocks Explorer: {{ s.blocksExplorer }} <br />
        Wallet Version: {{ s.version }} <br />
        Staking: {{ s.staking }} <br />
        Balance: {{ s.balance }} <br />
        Balance Staked: {{ s.stake }} <br />
        Total Balance: {{ s.balance + s.stake }} <br />
        Staking Weight: {{ s.weight / 100000000 }} <br />
        Testnet: {{ s.testnet }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import axiosService from "@/services/axiosService.ts";

import OneSignalVue from 'onesignal-vue';

export default {
  data: function () {
    return {
      response: "waiting...",
      stakingInfo: [],
      apiKeyPublic: [],
      selectedKey: 0,
    };
  },
  methods: {
    apiCall: async function (arrayValue) {
      axiosService
        .getCall(
          "",
          "v1/stakingInfo?apikeypublic=" +
            // encodeURIComponent(localStorage.getItem("hsi:apikeypublic"))
            encodeURIComponent(this.apiKeyPublic[arrayValue]["key"])
        )
        .then((response) => {
          this.stakingInfo = response.data;
          this.stakingInfo.forEach((element) => {
            element.d = new Date(
              Math.floor(element.utcTimestamp * 1000)
            ).toLocaleString();

            if (
              element.staking == false ||
              Math.abs(element.timeGap) > 0.5 ||
              element.blocksExplorer - element.blocks > 1
            ) {
              element.status = 0; //danger
            } else if (
              element.blocksExplorer - element.blocks > 0 ||
              element.version < 180505 ||
              element.timeGap == 0
            ) {
              element.status = 1; //warning
            } else element.status = 2; //correct

            if (element.timeGap == 0) element.timeGap = "n/a";
          });
          //localStorage.setItem('token', JSON.stringify(token))
          //console.log(response.data); // For now, logs out the response
        })
        .catch((error) => {
          this.response = "There was an error: " + error.response;
          //console.log("There was an error: ", error.response); // Logs out the error
        });
    },
    dropdownClick(index) {
      this.selectedKey = index;
      this.apiCall(this.selectedKey);
    },
  },
  created: async function () {
    this.apiKeyPublic = JSON.parse(localStorage.getItem("hsi:apikeypublic"));
    this.selectedKey = 0;
    this.apiCall(this.selectedKey);
  },
  beforeCreate() {

    // console.log('beforecreate');
    // this.$OneSignal.User.PushSubscription.optIn();

    // OneSignal.logout;
    // OneSignal.Slidedown.promptPush({force: true});
    // const isSupported = OneSignal.Notifications.isPushSupported();

    // OneSignal.login("9Bhxk11JNQJOli+G8TGG");

    // console.log('id',OneSignal.User.PushSubscription.id);

    // console.log('isSupported',isSupported)

  },
};
</script>

<style>
/* h2 {
  font-family: Helvetica, Arial, sans-serif;
  background-color: lightgray;
  font-weight: bold;
} */
</style>
