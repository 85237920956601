import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import Home from "../views/Home.vue";
import StakingInfo from "../views/StakingInfo.vue";
import UserAPI from "../views/UserAPI.vue";
import MainPage from "../views/MainPage.vue";
// import DeviceInfo from "../views/DeviceInfo.vue";
// import { authGuard } from "../auth/authGuard";

// import About from "../views/About.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "mainPage",
    component: MainPage,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: About,
  //   // beforeEnter: authGuard
  // },
  {
    path: "/StakingInfo",
    name: "stakingInfo",
    component: StakingInfo,
    // beforeEnter: authGuard
  },
  {
    path: "/UserAPI",
    name: "userAPI",
    component: UserAPI,
    // beforeEnter: authGuard
  },
  // {
  //   path: "/deviceinfo/:deviceid",
  //   name: "deviceinfo",
  //   component: DeviceInfo,
  //   // beforeEnter: authGuard,
  //   props: true,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
