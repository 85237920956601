<template>
  <div>
    <p class="test">User API Key</p>

    <div class="example">
      <!-- <b-form inline>
      <label for="inline-form-input-name">Api Key</label> -->

      <label>Api Key</label>
      <b-form-input
        id="inline-form-input-name"
        class="mb-2 mr-sm-2 mb-sm-0"
        v-model="apiKey"
        placeholder=""
      ></b-form-input>

      <b-button class="mr-1 mt-2" variant="primary" @click="onSubmit"
        >New</b-button
      >

      <DxDataGrid
        id="dataGrid"
        :data-source="apiKeyPublic"
        :show-borders="true"
        @row-inserted="onSaveApiKeyPublic('RowInserted')"
        @row-updated="onSaveApiKeyPublic('RowUpdated')"
        @row-removed="onSaveApiKeyPublic('RowRemoved')"
      >
        <DxEditing
          :allow-adding="true"
          :allow-updating="true"
          :allow-deleting="true"
          :use-icons="true"
          mode="form"
        ></DxEditing>
      </DxDataGrid>

      <br />

      <!-- <b-button class="mr-1 mt-2" variant="primary" @click="onSaveApiKeyPublic"
        >Save</b-button
      > -->
      <!-- </b-form> -->

      <!-- <b-input-group>
      <template #prepend>
        <b-input-group-text>Api Key</b-input-group-text>
      </template>
      <b-form-input v-model="apiKey"></b-form-input>
      <b-button variant="primary" @click="onSaveApiKey">Save</b-button>
    </b-input-group>

    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="form.name"
          placeholder="Enter name"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
      <pre class="m-0">Name: {{ userName }}</pre>
      <pre class="m-0">ApiKey: {{ apiKey }}</pre>
      <pre class="m-0">Error: {{ err }}</pre>
    </b-card> -->
    </div>
  </div>
</template>

 <script>
// import axiosService from "@/services/axiosService.js";
import axios from "axios";
import { DxDataGrid, DxEditing } from "devextreme-vue/data-grid";

export default {
  data() {
    return {
      form: {
        name: "",
      },
      show: true,
      apiKey: "",
      apiKeyPublic: [],
      userName: "",
      err: "",
      fields: [
        {
          key: "key",
          label: "Api Key Public",
        },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  components: {
    DxDataGrid,
    DxEditing,
  },
  methods: {
    onSubmit(event) {
      if (this.apiKeyPublic == null) this.apiKeyPublic = [];
      axios({
        method: "post",
        url: `` + process.env.VUE_APP_AXIOS_BASE_URL + `` + "/v1/User/",
        data: {
          UserName: this.form.name,
        },
      })
        .then((response) => {
          this.apiKey = response.data.apiKey;
          //this.apiKeyPublic = response.data.apiKeyPublic;
          let akp = { key: response.data.apiKeyPublic };
          this.apiKeyPublic.push(akp);
          this.userName = response.data.userName;
          this.form.name = "";
          this.onSaveApiKeyPublic();
          // localStorage.setItem("hsi:apikey", response.data.apiKey);
          // console.log(response);
        })
        .catch((error) => {
          this.apiKey = "error creating api key";
          //this.apiKeyPublic = [];
          this.err = error.response;
          console.log(error.response);
        });

      // axiosService
      //   .getCall("", "v1/stakingInfo")
      //   .then((response) => {
      //     this.stakingInfo = response.data;
      //     this.stakingInfo.forEach((element) => {
      //       element.d = new Date(
      //         Math.floor(element.utcTimestamp * 1000)
      //       ).toLocaleString();
      //     console.log(response.data); // For now, logs out the response
      //   })
      //   .catch((error) => {
      //     this.response = "There was an error: " + error.response;
      //     console.log("There was an error: ", error.response); // Logs out the error
      //   });

      event.preventDefault();
      // alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.name = "";
      this.apiKey = "";
      this.apiKeyPublic = [];
      this.userName = "";
      this.err = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onSaveApiKeyPublic(event) {
      //localStorage.setItem("hsi:apikeypublic", this.apiKeyPublic);

      for (var i = 0; i < this.apiKeyPublic.length; i++) {
        delete this.apiKeyPublic[i]["__KEY__"];
      }

      localStorage.setItem(
        "hsi:apikeypublic",
        JSON.stringify(this.apiKeyPublic)
      );
    },
  },
  created() {
    //this.apiKeyPublic = localStorage.getItem("hsi:apikeypublic");
    this.apiKeyPublic = JSON.parse(localStorage.getItem("hsi:apikeypublic"));
  },
};
</script>

<style>
.example {
  color: blue;
  text-align: left;
  margin-left: 60px;
  margin-right: 60px;
}
</style>
