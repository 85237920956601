import Vue from "vue";
import router from "./router";
import App from "./App.vue";
// import "./registerServiceWorker";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "devextreme/dist/css/dx.light.css";

import OneSignalVue from 'onesignal-vue'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

Vue.use(OneSignalVue);

new Vue({
  router,
  render: (h) => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: '' + process.env.VUE_APP_ONE_SIGNAL + ''});
  }
}).$mount("#app");
